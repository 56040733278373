import React, { ReactNode, useState } from 'react';
import styled from 'styled-components/macro';
import { theme } from 'styled-tools';
import { InputWrapper as InputWrapperBase }  from '../index';
import dayjs from 'dayjs';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const InputWrapper = styled(InputWrapperBase)`
  input {
    font-size: 0.875rem;
  }

  & div.react-datepicker__day--selected {
    background-color: ${theme('colors.blue')};
  }

  & div.react-datepicker-wrapper {
    width: 100%;
  }
`;

interface DateFieldProps extends Omit<ReactDatePickerProps, 'onChange'> {
  label?: string | ReactNode,
  error?: string,
  isFilter?: boolean,
  onChange?(
    date: string | null,
    event: React.SyntheticEvent<any> | undefined,
  ): void;
}

const ErrorMessage = styled.span`
  color: ${theme('colors.red')};
`;

function DateField({ label, error, onChange, value, isFilter, ...datePickerProps }: DateFieldProps) {
  const [selected, setSelected] = useState<Date|null|undefined>(value ? dayjs(value).toDate() : null);

  const handleChange = (date: Date | null, event: React.SyntheticEvent<any> | undefined): void => {
    if (date instanceof Date) {
      const dayte = dayjs(date);
      setSelected(dayte.toDate());
      onChange && onChange(dayte.toISOString(), event);
    } else {
      setSelected(null);
      onChange && onChange(null, event);
    }
  }

  return (
    <InputWrapper isFilter={isFilter}>
      {label && <label>{label}</label>}
      <DatePicker
        {...datePickerProps}
        selected={selected}
        onChange={handleChange}
      />
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </InputWrapper>
  );
}

export default DateField;
