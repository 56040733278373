import React, { useEffect, useState, FocusEventHandler } from 'react';
import { gql, useQuery } from "@apollo/client";
import styled from 'styled-components';
import { DropDown, useFocusEventHandler, useSearchState, Option } from './index';
import { CalendarResource } from 'types';

interface CalendarResourceFieldProps {
  onChange?: Function;
  value?: number;
  jobType?: number;
  trade?: number|null;
  disabled?: boolean;
}

interface SearchResultsProps {
  isFocused: boolean;
  jobType?: number;
  trade?: number|null;
  handleClick: Function;
  handleFocusEvent: FocusEventHandler<HTMLLIElement>;
  handleOptionSelected: Function;
  searchFilter: string;
  value?: number;
}

// TODO: Implement jobType passing in CP1-105
const GET_CALENDAR_RESOURCES = gql`
  query getCalendarResources($jobType: ID!, $trade: ID!, $search: String) {
    getCalendarResources(filter: {
      jobType: $jobType,
      trade: $trade,
      search: $search,
    }) {
      id
      name
      isActive
    }
  }
`;

const CalendarResourceFieldWrapper = styled.div`
  position: relative;

  ${DropDown} {
    max-height: 200px;
  }
`;

function parseResponse(data: any): Option[] {
  return data ? data.getCalendarResources.map((option: CalendarResource) => {
    return { value: option.id, label: option.name }
  }) : [];
}

function SearchResults({
  isFocused,
  handleClick,
  handleFocusEvent,
  handleOptionSelected,
  jobType,
  trade,
  searchFilter,
  value,
}: SearchResultsProps) {
  const { loading, data } = useQuery(GET_CALENDAR_RESOURCES, {
    skip: !jobType || !trade,
    variables: { jobType: jobType, trade: trade, search: searchFilter },
    onCompleted: (data) => {
      if (value && searchFilter === "") {
        const optionToSelect = parseResponse(data).find((option) => option.value === value);
        handleOptionSelected(optionToSelect?.label);
      }
    }
  });

  return (
    <>
      {(!loading && isFocused) &&
        <DropDown>
          {parseResponse(data).map((option: Option) => (
            <li onFocus={handleFocusEvent} onClick={() => handleClick(option)} key={option.value} tabIndex={0}>{option.label}</li>
          ))}
        </DropDown>
      }
    </>
  );
}

function CalendarResourceField({
  onChange,
  jobType,
  trade,
  value,
  disabled = false,
}: CalendarResourceFieldProps) {
  const { searchFilter, searchValue, handleOptionSelected, onSearchChange } = useSearchState('');
  const { isFocused, setIsFocused, handleFocusEvent } = useFocusEventHandler();
  const [isInit, setIsInit] = useState(true);

  useEffect(() => {
    if (!isInit) {
      onChange && onChange(null);
      handleOptionSelected('');
    }
    setIsInit(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trade])

  const handleClick = (option: Option): void => {
    handleOptionSelected(option.label);
    setIsFocused(false);
    onChange && onChange(option.value);
  }

  return (
    <CalendarResourceFieldWrapper>
      <input
        id='calendar-resource-field'
        value={searchValue}
        onChange={onSearchChange}
        onFocus={handleFocusEvent}
        onBlur={handleFocusEvent}
        autoComplete='off'
        disabled={disabled || !trade}
      />
      <SearchResults
        isFocused={isFocused}
        searchFilter={searchFilter}
        handleClick={handleClick}
        handleFocusEvent={handleFocusEvent}
        handleOptionSelected={handleOptionSelected}
        value={value}
        jobType={jobType}
        trade={trade}
      />
    </CalendarResourceFieldWrapper>
  );
}

export default CalendarResourceField;
