import React, { useState, useEffect } from 'react';
import { AppBar, Box, Button, Popover, Toolbar, Divider, useMediaQuery, useTheme, Menu, MenuItem } from '@material-ui/core';
import { useAuthToken, useCanEditJobs, useCanEditEvents, useIsAdmin } from 'config/auth';
import { useHistory, Link } from 'react-router-dom';
import { useCallback } from 'react';
import styled from 'styled-components/macro';
import Icon from 'components/Icon';
import SearchInput from 'components/Forms/TypeAheadField/SearchInput';
import { theme } from "styled-tools";

const NavOffset = styled.div`
  height: ${theme('headerOffset')};
`;

const ButtonsWrap = styled(Box)`
  .MuiButton-root, .MuiInput-root {
    margin: 0 0.4rem;
  }

  .MuiInput-root {
    margin-right: 1.5rem;
  }

  ${props => props.theme.breakpoints.down('sm')} {
    .MuiButton-root, .MuiInput-root {
      font-size: 0.8rem;
    }

    .MuiButton-label > svg {
      margin-right: 0.25rem;
    }
  }

  .add-new-button {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  @media (hover: none) {
    .Mui-outlinedPrimary:hover {
      background-color: ${props => props.theme.colors.lightBlue};
    }
  }
`;

const ButtonText = styled.span`
  white-space: nowrap;
`

function Header({ toggleJobSlideOut, toggleEventSlideOut }: any) {
  const theme = useTheme();
  const isAdmin = useIsAdmin();
  const canEditJobs = useCanEditJobs();
  const canEditEvents = useCanEditEvents();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const [authToken, , removeAuthToken] = useAuthToken();
  const history = useHistory();
  const [anchorElForReportMenu, setAnchorElForReportMenu] = useState(null);
  const [anchorElForMobileMenu, setAnchorElForMobileMenu] = useState(null);
  const [anchorElForSearchMenu, setAnchorElForSearchMenu] = useState(null);

  const toggleReports = (event: any) => setAnchorElForReportMenu(event.currentTarget);
  const closeReports = () => setAnchorElForReportMenu(null);

  const toggleMobileMenu = (event: any) => setAnchorElForMobileMenu(event.currentTarget);
  const closeMobileMenu = () => setAnchorElForMobileMenu(null);

  const toggleSearchMenu = (event: any) => setAnchorElForSearchMenu(event.currentTarget);
  const closeSearchMenu = () => setAnchorElForSearchMenu(null);

  const onLogoutClick = useCallback(() => {
    removeAuthToken();
    history.push('/login');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removeAuthToken]);

  useEffect(() => {
    // if viewport has changed, close any open menus/ popovers
    closeReports();
    closeSearchMenu();
    closeMobileMenu();
  }, [isTablet])

  return (
    <header>
      <AppBar position="fixed">
        <Toolbar>
          {(canEditJobs || canEditEvents) && (
            <ButtonsWrap>
              {canEditJobs && (
                <Button variant="outlined" color='primary' className='add-new-button' onClick={toggleJobSlideOut}>
                  <Icon icon='plus' size={(!isMobile ? 0.75 : 1)} />
                  <ButtonText>
                    {!isTablet ? <>&nbsp; New Job</> : 'Job'}
                  </ButtonText>
                </Button>
              )}
              {canEditEvents && (
                <Button variant="outlined" color='primary' className='add-new-button' onClick={toggleEventSlideOut}>
                  <Icon icon='plus' size={(!isMobile ? 0.75 : 1)} />
                  <ButtonText>
                    {!isTablet ? <>&nbsp; New Event</> : 'Event'}
                  </ButtonText>
                </Button>
              )}
            </ButtonsWrap>
          )}
          <ButtonsWrap ml='auto' display='flex'>
            {isAdmin && !isTablet && (
              <>
                <Button variant='contained' color='secondary' href={`${process.env.REACT_APP_ADMIN_URL}?token=${authToken}`}>
                  <ButtonText>Administration &nbsp; <Icon icon='chevronDown' size={0.75} /></ButtonText>
                </Button>
                <Button variant='contained' color='secondary' aria-controls="reports-menu" aria-haspopup="true" onClick={toggleReports}>
                  <ButtonText>Reports &nbsp; <Icon icon='chevronDown' size={0.75} /></ButtonText>
                </Button>
                <Menu
                  id="reports-menu"
                  anchorEl={anchorElForReportMenu}
                  keepMounted
                  open={Boolean(anchorElForReportMenu)}
                  onClose={closeReports}
                >
                  <MenuItem component={Link} to='reports/job-report'>Job Report</MenuItem>
                </Menu>
              </>
            )}
            {isTablet && (
              <>
                <Button variant='contained' aria-controls="search-menu" aria-haspopup="true" onClick={toggleSearchMenu}>
                  <ButtonText><Icon icon='search' size={0.75} /></ButtonText>
                </Button>
                <Popover
                  id="search-menu"
                  anchorEl={anchorElForSearchMenu}
                  keepMounted
                  open={Boolean(anchorElForSearchMenu)}
                  onClose={closeSearchMenu} anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <SearchInput />
                </Popover>
                <Button variant='contained' aria-controls="mobile-menu" aria-haspopup="true" onClick={toggleMobileMenu}>
                  <ButtonText><Icon icon='chevronDown' size={0.75} /></ButtonText>
                </Button>
                <Menu
                  id="mobile-menu"
                  anchorEl={anchorElForMobileMenu}
                  keepMounted
                  open={Boolean(anchorElForMobileMenu)}
                  onClose={closeMobileMenu}
                >
                  {isAdmin && (
                    <MenuItem component={'a'} href={`${process.env.REACT_APP_ADMIN_URL}?token=${authToken}`}>
                      Administration
                    </MenuItem>
                  )}
                  <Divider />

                  <MenuItem onClick={onLogoutClick}>Sign Out</MenuItem>
                </Menu>
              </>
            )}
            {!isTablet && (
              <>
                <SearchInput />
                <Button variant='contained' color='primary' onClick={onLogoutClick}>
                  <ButtonText>Sign Out</ButtonText>
                </Button>
              </>
            )}
          </ButtonsWrap>
        </Toolbar>
      </AppBar>
      <NavOffset />
    </header>
  )
}

export default Header;
