import React, { ChangeEventHandler } from 'react';
import { JobAttribute } from 'types';
import { TextArea, Select, InputWrapper } from 'components/Forms';

interface JobAttributeFieldProps {
  jobAttribute: JobAttribute,
  jobIndex?: number,
  jobAttributeValues: any[]|undefined,
  onFieldUpdate: Function,
  onFieldChange: ChangeEventHandler,
  isMultipleJobs?: boolean,
}

interface Option {
  label: string,
  value: string,
}

function buildOptions(optionsString = ''): Option[] {
  return optionsString.split('\n').map((opStr: string) => {
    const [label, value] = opStr.split(':');
    return { label, value };
  });
}

function JobAttributeField({ jobAttribute, jobIndex, jobAttributeValues, onFieldUpdate, onFieldChange, isMultipleJobs = true }: JobAttributeFieldProps) {
  const identifier = isMultipleJobs ? `jobs[${jobIndex}].jobAttributeValues[${jobAttribute.id}]` : `jobAttributeValues[${jobAttribute.id}]`;
  const isCheckbox = jobAttribute.fieldType === 'checkbox';
  const baseProps = {
    id: identifier,
    name: identifier,
    value: (jobAttributeValues && jobAttributeValues[jobAttribute.id]) || '',
    onChange: onFieldChange,
  };

  return (
    <InputWrapper isCheckbox={isCheckbox}>
      {(jobAttribute.label && !isCheckbox) &&
        <label>{jobAttribute.label}</label>
      }
      {(jobAttribute.fieldType === 'text' || jobAttribute.fieldType === 'number') &&
        <input {...baseProps} type={jobAttribute.fieldType} />
      }
      {jobAttribute.fieldType === 'textarea' &&
        <TextArea {...baseProps} />
      }
      {isCheckbox &&
        <label>
          {jobAttribute.label ?? ''}
          <input {...baseProps} type='checkbox' checked={baseProps.value} />
        </label>
      }
      {(jobAttribute.fieldType === 'select' || jobAttribute.fieldType === 'multiselect') &&
        <Select
          {...baseProps}
          onChange={(val: any) => onFieldUpdate(`jobAttributeValues[${jobAttribute.id}]`, jobIndex, val)}
          options={buildOptions(jobAttribute?.fieldOptions)}
          isMulti={jobAttribute.fieldType === 'multiselect'}
        />
      }
    </InputWrapper>
  );
}

export default JobAttributeField;
