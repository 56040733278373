import { useCallback, useEffect, useMemo, useState } from 'react';
import { Options, RRule, rrulestr, ByWeekday } from 'rrule';
import { InputWrapper } from 'components/Forms/Styled';
import Select from 'components/Forms/Select';
import styled from 'styled-components/macro';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-left: 0.5rem;

  & > div {
    flex: 0 0 50%;
    margin-bottom: 0.5rem;
    padding: 0 0.5rem;
  }
`;

const IntervalInput = styled.input`
  max-width: 3.125rem;
  margin-left: .5rem;
  margin-right: .5rem;
`;

const frequencyOptions = [
  { label: 'Weekly', value: RRule.WEEKLY },
  { label: 'Monthly', value: RRule.MONTHLY },
];

const weekdayOptions = [
  { label: 'Monday', value: RRule.MO.weekday },
  { label: 'Tuesday', value: RRule.TU.weekday },
  { label: 'Wednesday', value: RRule.WE.weekday },
  { label: 'Thursday', value: RRule.TH.weekday },
  { label: 'Friday', value: RRule.FR.weekday },
  { label: 'Saturday', value: RRule.SA.weekday },
  { label: 'Sunday', value: RRule.SU.weekday },
];

const setPosOptions = [
  { label: 'First', value: 1 },
  { label: 'Second', value: 2 },
  { label: 'Third', value: 3 },
  { label: 'Fourth', value: 4 },
  { label: 'Last', value: -1 },
];

const defaultRuleOptions: Partial<Options> = {
  freq: RRule.WEEKLY,
  interval: 1,
  byweekday: null,
  bysetpos: null,
};

interface RRuleInputProps {
  startDate: Date;
  endDate?: Date;
  value?: string;
  onChange?(value: string): void;
}

function RRuleInput({ startDate, endDate, onChange, value }: RRuleInputProps) {
  const [lastValue, setLastValue] = useState<string|null|undefined>(value);

  const initialOpts: Partial<Options> = useMemo(() => value ? rrulestr(value).options : defaultRuleOptions, [value]);

  const [frequency, setFrequency] = useState(initialOpts.freq);
  const [interval, setInterval] = useState<number|undefined>(initialOpts.interval);
  const [byWeekday, setByWeekday] = useState<ByWeekday|ByWeekday[]|null|undefined>(initialOpts.byweekday);
  const [bySetPos, setBySetPos] = useState<number|number[]|null|undefined>(initialOpts.bysetpos);

  // Initialize state from value, if it changes.
  useEffect(() => {
    if (value && value !== lastValue) {
      setFrequency(initialOpts.freq);
      setInterval(initialOpts.interval);
      setByWeekday(initialOpts.byweekday);
      setBySetPos(initialOpts.bysetpos);
    }
  }, [lastValue, value, initialOpts])

  useEffect(() => {
    const rule = new RRule({
      freq: frequency,
      interval: interval,
      byweekday: byWeekday,
      bysetpos: bySetPos,
      dtstart: startDate,
      until: endDate
    });

    const ruleStr = rule.toString();

    if (ruleStr !== value) {
      setLastValue(ruleStr);
      onChange && onChange(ruleStr);
    }
  }, [frequency, interval, byWeekday, bySetPos, startDate, endDate, value, onChange]);

  const handleFrequencyChange = useCallback((newVal: number) => {
    setFrequency(newVal);
    setByWeekday(null);
    setBySetPos(null);
  }, []);

  return (
    <Wrapper>
      <InputWrapper>
        <label>Repeat</label>
        <Select
          id='rruleFrequency'
          name='rruleFrequency'
          value={frequency}
          onChange={handleFrequencyChange}
          options={frequencyOptions}
        />
      </InputWrapper>

      {frequency === RRule.WEEKLY && (
        <>
          <InputWrapper inline flexEnd>
            <label>
              Every
              <IntervalInput
                id='rruleIntervalWeekly'
                name='rruleInterval'
                type='number'
                value={interval || 1}
                onChange={(e) => setInterval(e.target.value ? parseInt(e.target.value) : undefined)}
              />
              Week(s)
            </label>
          </InputWrapper>
          <InputWrapper flexBasis='100%'>
            <label>On Selected Days of Week</label>
            <Select
              id='rruleByWeekday'
              name='rruleByWeekday'
              value={byWeekday}
              onChange={(value: ByWeekday) => setByWeekday(value)}
              options={weekdayOptions}
              isMulti
            />
          </InputWrapper>
        </>
      )}

      {frequency === RRule.MONTHLY && (
        <>
          <InputWrapper inline flexEnd>
            <label>
              Every
              <IntervalInput
                id='rruleIntervalMonthly'
                name='rruleInterval'
                type='number'
                value={interval}
                onChange={(e) => setInterval(e.target.value ? parseInt(e.target.value) : undefined)}
              />
              Month(s)
            </label>
          </InputWrapper>
          <InputWrapper>
            <label>On The</label>
            <Select
              id='rruleBySetPos'
              name='rruleBySetPos'
              value={bySetPos}
              onChange={(value: number) => setBySetPos(value)}
              options={setPosOptions}
            />
          </InputWrapper>
          <InputWrapper>
            <label>Selected Day of Week</label>
            <Select
              id='rruleByWeekday'
              name='rruleByWeekday'
              value={byWeekday}
              onChange={(value: ByWeekday) => setByWeekday(value)}
              options={weekdayOptions}
            />
          </InputWrapper>
        </>
      )}
    </Wrapper>
  );
}

export default RRuleInput;
