import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache } from '@apollo/client';
import { useAuthToken } from './auth';

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GATEWAY_API_URL + '/graphql',
  headers: {},
});

const authMiddleware = (authToken?: string) =>
  new ApolloLink((operation, forward) => {
    if (authToken) {
      operation.setContext({
        headers: {
          authorization: `Bearer ${authToken}`,
        },
      });
    }

    return forward(operation);
  });

export const useAppApolloClient = () => {
  const [authToken] = useAuthToken();

  return new ApolloClient({
    uri: process.env.REACT_APP_GATEWAY_API_URL + '/graphql',
    defaultOptions: {},
    cache: new InMemoryCache(),
    link: authMiddleware(authToken).concat(httpLink),
    connectToDevTools: true,
  });
}
