import React from 'react';
import { Helmet } from 'react-helmet-async';
import CssBaseline from '@material-ui/core/CssBaseline';
import { useIsAuthenticated } from '../../config/auth';
import Header from '../Header';
import styled from 'styled-components/macro';
import ScheduleEventSlideOut from 'components/SlideOut/ScheduleEventSlideOut';
import ScheduleJobSlideOut from 'components/SlideOut/ScheduleJobSlideOut';

interface LayoutProps {
  children: React.ReactNode,
  title?: string
}

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const Content = styled.div`
  flex: 1 0 70%;
  max-width: 100%;
`;

function Layout({ children, title = "CFC Calendar" }: LayoutProps) {
  const isAuthenticated = useIsAuthenticated();
  const [jobSlideOutOpen, setJobSlideOutOpen] = React.useState(false);
  const [eventSlideOutOpen, setEventSlideOutOpen] = React.useState(false);
  const toggleJobSlideOut = () => {
    if (eventSlideOutOpen && !jobSlideOutOpen) {
      setEventSlideOutOpen(false);
    }

    setJobSlideOutOpen(!jobSlideOutOpen);
  }
  const toggleEventSlideOut = () => {
    if (jobSlideOutOpen && !eventSlideOutOpen) {
      setJobSlideOutOpen(false);
    }

    setEventSlideOutOpen(!eventSlideOutOpen);
  }

  return (
    <>
      <CssBaseline />
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {isAuthenticated ? (
        <>
          <Header toggleJobSlideOut={toggleJobSlideOut} toggleEventSlideOut={toggleEventSlideOut} />
          <ContentContainer>
            <ScheduleJobSlideOut slideOutOpen={jobSlideOutOpen} toggleSlideOut={toggleJobSlideOut} />
            <ScheduleEventSlideOut slideOutOpen={eventSlideOutOpen} toggleSlideOut={toggleEventSlideOut} />
            <Content id='content-root'>{children}</Content>
          </ContentContainer>
        </>
      ) : (
        <>{children}</>
      )}
    </>
  )
}

export default Layout;
