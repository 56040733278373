import React, { ChangeEventHandler } from 'react';
import styled from 'styled-components/macro';
import { Button, Typography } from '@material-ui/core';
import { InputWrapper, DateField, TextArea, RRuleInput } from 'components/Forms';
import { theme } from 'styled-tools';
import dayjs from 'dayjs';

interface CreateEventFormProps {
  title?: string,
  event?: any,
  index: number,
  onCancel: Function,
  onFieldUpdate: Function,
  onFieldChange: ChangeEventHandler,
}

const ManageJobContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Section = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-left: 0.5rem;

  & > div {
    flex: 0 0 50%;
    margin-bottom: 0.5rem;
    padding: 0 0.5rem;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;

  button:first-of-type {
    margin-left: 0.5rem;
  }
  button:last-of-type {
    margin-right: 0.5rem;
  }
`;

const RRuleWrapper = styled.div`
  && {
    flex-basis: 100%;
  }
`;

const ErrorMessage = styled.p`
  color: ${theme('colors.red')};
  margin: 0;
  margin-top: 0.5rem;
`;

function CreateEventForm({ title = 'Schedule an Event', event, index, onCancel, onFieldUpdate, onFieldChange }: CreateEventFormProps) {
  return (
    <ManageJobContainer>
      <Typography variant='h5'>{title}</Typography>
      {alert && <ErrorMessage>{alert}</ErrorMessage>}
      <Section>
        <InputWrapper flexBasis='100%'>
          <label>Name</label>
          <input
            id={`events[${index}].name`}
            name={`events[${index}].name`}
            type='text'
            value={event.name}
            onChange={onFieldChange}
          />
        </InputWrapper>
        <DateField
          id={`events[${index}].startDate`}
          name={`events[${index}].startDate`}
          label='Start Date'
          value={event.startDate}
          onChange={(date) => onFieldUpdate('startDate', index, date)}
        />
        <DateField
          id={`events[${index}].endDate`}
          name={`events[${index}].endDate`}
          label='End Date'
          value={event.endDate}
          onChange={(date) => onFieldUpdate('endDate', index, date)}
        />
        <InputWrapper isCheckbox>
          <label>
            Recurring
            <input
              id={`events[${index}].isRecurring`}
              name={`events[${index}].isRecurring`}
              type='checkbox'
              checked={event.isRecurring}
              onChange={(e) => {
                // If unchecked, clear out the recurring field.
                if (!e.target.checked) {
                  onFieldUpdate('recurring', index, null);
                }
                onFieldUpdate('isRecurring', index, e.target.checked);
              }}
            />
          </label>
        </InputWrapper>

        {event.isRecurring && (
          <RRuleWrapper>
            <RRuleInput
              startDate={event.startDate ? dayjs(event.startDate).toDate() : new Date()}
              endDate={event.endDate ? dayjs(event.endDate).toDate() : undefined}
              value={event.recurring}
              onChange={(value: string) => onFieldUpdate('recurring', index, value)}
            />
          </RRuleWrapper>
        )}

        <InputWrapper flexBasis='100%'>
          <label>Additional Notes</label>
          <TextArea
            id={`events[${index}].notes`}
            name={`events[${index}].notes`}
            value={event.notes || ''}
            onChange={onFieldChange}
          />
        </InputWrapper>
      </Section>

      <ButtonWrapper>
       <Button variant='outlined' onClick={() => onCancel(index)}>Cancel Event</Button>
      </ButtonWrapper>
    </ManageJobContainer>
  );
}

export default CreateEventForm;
