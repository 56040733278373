import React, { useEffect, useState } from 'react';
import { useTheme } from 'styled-components/macro';
import ReactSelect from "react-select";

interface SelectComponent {
  id: string,
  name: string,
  options?: Option[],
  onChange?: Function,
  value?: any,
  isMulti?: boolean,
  placeholder?: string,
}

interface Option {
  value: string|number|undefined|null,
  label: string,
}

function customStyles(theme: any) {
  return {
    control: (provided: any, state: any) => ({
      ...provided,
      boxShadow: state.isFocused ? `0 0 0 1px ${theme.colors.black}` : provided.boxShadow,
      border: state.isFocused ? `1px solid ${theme.colors.black} !important` : provided.border,
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isSelected ? theme.colors.darkBlue : provided.backgroundColor,
    }),
    valueContainer: (provided: any, state: any) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      color: theme.colors.darkBlue,
      fontWeight: 'bold',
    }),
    singleValue: (provided: any, state: any) => ({
      ...provided,
      color: theme.colors.darkBlue,
      fontWeight: 'bold',
    }),
    multiValue: (provided: any, state: any) => ({
      ...provided,
      color: theme.colors.darkBlue,
      fontWeight: 'bold',
    }),
    multiValueLabel: (provided: any, state: any) => ({
      ...provided,
      color: theme.colors.darkBlue,
      fontWeight: 'bold',
    }),
  }
}

function getInitSelected(initValue: any, options: Option[]|undefined): Option|Option[]|undefined {
  if (Array.isArray(initValue)) {
    const selectedValues: Option[] = [];
    initValue.forEach((i: string) => {
      const matchedOption = options?.find((o: any) => i === o.value)
      if (matchedOption) {
        selectedValues.push(matchedOption);
      }
    });

    return selectedValues;
  } else {
    return options?.find((o: Option) => o.value === initValue);
  }
}

function Select({ id, name, options, onChange, value: initValue, isMulti = false, placeholder }: SelectComponent) {
  const [value, setValue] = useState(getInitSelected(initValue, options));
  const theme = useTheme();

  useEffect(() => {
    setValue(getInitSelected(initValue, options));
  }, [initValue, options]);

  const handleChange = (selectedOption: any) => {
    setValue(selectedOption)

    const val = Array.isArray(selectedOption)
      ? selectedOption.map((o: Option) => o.value)
      : selectedOption.value;
    onChange && onChange(val);
  }

  return (
    <ReactSelect
      id={id}
      name={name}
      options={options}
      value={value}
      onChange={handleChange}
      styles={customStyles(theme)}
      placeholder={placeholder}
      isMulti={isMulti}
    />
  )
}

export default Select;
