import * as ls from "local-storage";
import dayjs from "dayjs";
import {
  Event,
  Job,
  JobStatus,
  ProjectManager,
  Trade,
  Warehouse,
} from "types/index";
import { VIEW_MODES } from "./index";
import { dateGMT } from "utils/dates";
import memoize from "lodash.memoize";

export interface Filters {
  installTrades: number[];
  warrantyTrades: number[];
  projectManagers: number[];
  warehouses: number[];
  jobStatuses: number[];
}

type OpenStateStorage = { isOpen: boolean } | null;
type TradesOpen =  { [key: string]: OpenStateStorage } | null

export interface CalendarState {
  viewMode: string;
  dateBasis: Date | null;
  startDate: Date | null;
  endDate: Date | null;
  dates: Date[];
  installTrades: Trade[];
  warrantyTrades: Trade[];
  warehouses: Warehouse[];
  projectManagers: ProjectManager[];
  jobStatuses: JobStatus[];
  selectedJobId?: number;
  selectedEventId?: number;
  filters: Filters;
  cacheUpdate: boolean;
  tradesOpen: TradesOpen;
  resourcesSearch: string;
}

// dateBasis is always first day of the week(mon) for the given date
export function calcDateBasis(date = new Date(), viewMode: string) {
  switch (viewMode) {
    case VIEW_MODES.ONE_DAY:
      return dateGMT(date, true).toDate();
    case VIEW_MODES.TEN_DAY:
      return dateGMT(date, true).startOf("w").add(12, 'hours').add(4, 'day').toDate();
    default:
      // To workaround a bug with dayjs when adding 1 day to a date when daylight savings time
      // switches over, we add 12 hours to the day before adding 1 day to ensure it increments
      // the date properly. This fixes a bug where in Mountain Time calendar was still
      // showing Sun-Sat instead of Mon-Sun.
      return dateGMT(date, true).startOf("w").add(12, 'hours').add(1, 'day').toDate();
  }
}

export function calcStartDate(dateBasis: Date, viewMode: string) {
  const daysToSub = viewMode === VIEW_MODES.TEN_DAY ? 3 : 0;
  return dateGMT(dateBasis).subtract(daysToSub, "day").toDate();
}

export function calcEndDate(dateBasis: Date, viewMode: string) {
  const daysToAdd = viewMode === VIEW_MODES.ONE_DAY ? 0 : 6;
  return dateGMT(dateBasis).add(daysToAdd, "day").toDate();
}

enum ActionType {
  ToggleViewMode = "calendarContext/TOGGLE_VIEW_MODE",
  UpdateFilters = "calendarContext/UPDATE_FILTERS",
  ToToday = "calendarContext/TO_TODAY",
  ToNext = "calendarContext/TO_NEXT",
  ToPrev = "calendarContext/TO_PREV",
  OpenJobDetail = "calendarContext/OPEN_JOB_DETAIL",
  CloseJobDetail = "calendarContext/CLOSE_JOB_DETAIL",
  OpenEventDetail = "calendarContext/OPEN_EVENT_DETAIL",
  CloseEventDetail = "calendarContext/CLOSE_EVENT_DETAIL",
  SetInstallTrades = "calendarContext/SET_INSTALL_TRADES",
  SetWarrantyTrades = "calendarContext/SET_WARRANTY_TRADES",
  SetWarehouses = "calendarContext/SET_WAREHOUSES",
  SetProjectManagers = "calendarContext/SET_PROJECT_MANAGERS",
  SetJobStatuses = "calendarContext/SET_JOB_STATUSES",
  SetDates = "calendarContext/SET_DATES",
  SetCacheUpdate = "calendarContext/SET_CACHE_UPDATE",
  SetTradeOpen = "calendarContext/SET_TRADE_OPEN",
  OpenTrades = "calendarContext/OPEN_TRADES",
  SetResourcesSearch = "calendarContext/SET_RESOURCES_SEARCH",
}

interface ToggleViewModeAction {
  type: ActionType.ToggleViewMode;
}
interface UpdateFiltersAction {
  type: ActionType.UpdateFilters;
  payload: { updatedFilters: Filters };
}
interface ToTodayAction {
  type: ActionType.ToToday;
}
interface ToNextAction {
  type: ActionType.ToNext;
}
interface ToPrevAction {
  type: ActionType.ToPrev;
}
interface OpenJobDetailAction {
  type: ActionType.OpenJobDetail;
  payload: { job: Job };
}
interface CloseJobDetailAction {
  type: ActionType.CloseJobDetail;
  payload: { deleted: boolean };
}
interface OpenEventDetailAction {
  type: ActionType.OpenEventDetail;
  payload: { event: Event };
}
interface CloseEventDetailAction {
  type: ActionType.CloseEventDetail;
  payload: { deleted: boolean };
}
interface SetInstallTradesAction {
  type: ActionType.SetInstallTrades;
  payload: { installTrades: Trade[] };
}
interface SetWarrantyTradesAction {
  type: ActionType.SetWarrantyTrades;
  payload: { warrantyTrades: Trade[] };
}
interface SetWarehousesAction {
  type: ActionType.SetWarehouses;
  payload: { warehouses: Warehouse[] };
}
interface SetProjectManagersAction {
  type: ActionType.SetProjectManagers;
  payload: { projectManagers: ProjectManager[] };
}
interface SetJobStatusesAction {
  type: ActionType.SetJobStatuses;
  payload: { jobStatuses: JobStatus[] };
}
interface SetDatesAction {
  type: ActionType.SetDates;
  payload: { startDate: Date; endDate: Date };
}
interface SetCacheUpdateAction {
  type: ActionType.SetCacheUpdate;
  payload: { cacheUpdate: boolean };
}
interface SetTradeOpenAction {
  type: ActionType.SetTradeOpen;
  payload: { type: string, tradeId: number, isOpen: boolean };
}
interface OpenTradesAction {
  type: ActionType.OpenTrades;
  payload: { type: string, trades: Trade[] };
}
interface SetResourcesSearchAction {
  type: ActionType.SetResourcesSearch;
  payload: { search: string };
}

export type Action =
  | ToggleViewModeAction
  | UpdateFiltersAction
  | ToTodayAction
  | ToNextAction
  | ToPrevAction
  | OpenJobDetailAction
  | CloseJobDetailAction
  | OpenEventDetailAction
  | CloseEventDetailAction
  | SetInstallTradesAction
  | SetWarrantyTradesAction
  | SetWarehousesAction
  | SetProjectManagersAction
  | SetJobStatusesAction
  | SetDatesAction
  | SetCacheUpdateAction
  | SetTradeOpenAction
  | OpenTradesAction
  | SetResourcesSearchAction;

export function toggleViewMode(): ToggleViewModeAction {
  return { type: ActionType.ToggleViewMode };
}

export function updateFilters(updatedFilters: Filters): UpdateFiltersAction {
  return { type: ActionType.UpdateFilters, payload: { updatedFilters } };
}

export function toToday(): ToTodayAction {
  return { type: ActionType.ToToday };
}

export function toNext(): ToNextAction {
  return { type: ActionType.ToNext };
}

export function toPrev(): ToPrevAction {
  return { type: ActionType.ToPrev };
}

export function openJobDetail(job: Job): OpenJobDetailAction {
  return { type: ActionType.OpenJobDetail, payload: { job } };
}

export function closeJobDetail(deleted: boolean): CloseJobDetailAction {
  return { type: ActionType.CloseJobDetail, payload: { deleted } };
}

export function openEventDetail(event: Event): OpenEventDetailAction {
  return { type: ActionType.OpenEventDetail, payload: { event } };
}

export function closeEventDetail(deleted: boolean): CloseEventDetailAction {
  return { type: ActionType.CloseEventDetail, payload: { deleted } };
}

export function setInstallTrades(
  installTrades: Trade[]
): SetInstallTradesAction {
  return { type: ActionType.SetInstallTrades, payload: { installTrades } };
}

export function setWarrantyTrades(
  warrantyTrades: Trade[]
): SetWarrantyTradesAction {
  return { type: ActionType.SetWarrantyTrades, payload: { warrantyTrades } };
}

export function setWarehouses(warehouses: Warehouse[]): SetWarehousesAction {
  return { type: ActionType.SetWarehouses, payload: { warehouses } };
}

export function setProjectManagers(
  projectManagers: ProjectManager[]
): SetProjectManagersAction {
  return { type: ActionType.SetProjectManagers, payload: { projectManagers } };
}

export function setJobStatuses(jobStatuses: JobStatus[]): SetJobStatusesAction {
  return { type: ActionType.SetJobStatuses, payload: { jobStatuses } };
}

export function setDates(startDate: Date, endDate: Date): SetDatesAction {
  return { type: ActionType.SetDates, payload: { startDate, endDate } };
}

export function setCacheUpdate(cacheUpdate: boolean): SetCacheUpdateAction {
  return { type: ActionType.SetCacheUpdate, payload: { cacheUpdate } };
}

export function setTradeOpen(type: string, tradeId: number, isOpen: boolean): SetTradeOpenAction {
  return { type: ActionType.SetTradeOpen, payload: { type, tradeId, isOpen } };
}

export function openTrades(type: string, trades: Trade[]): OpenTradesAction {
  return { type: ActionType.OpenTrades, payload: { type, trades } };
}

export function setResourcesSearch(search: string): SetResourcesSearchAction {
  return { type: ActionType.SetResourcesSearch, payload: { search } };
}

const getDates = memoize((startDate, endDate) => {
  const dates: Date[] = [];
  let currentDate = startDate;
  while (currentDate <= endDate) {
    dates.push(new Date(currentDate));
    currentDate = dateGMT(currentDate).add(1, "day").toDate();
  }
  return dates;
});

const ACTION_HANDLERS: { [index: string]: Function } = {
  [ActionType.ToggleViewMode]: (state: CalendarState, action: Action) => {
    const newViewMode =
      state.viewMode === VIEW_MODES.SEVEN_DAY
        ? VIEW_MODES.TEN_DAY
        : VIEW_MODES.SEVEN_DAY;
    const startDate =
      state.dateBasis && calcStartDate(state.dateBasis, newViewMode);

    return Object.assign({}, state, {
      viewMode: newViewMode,
      startDate: startDate,
      dates: getDates(startDate, state.endDate),
    });
  },
  [ActionType.UpdateFilters]: (
    state: CalendarState,
    action: UpdateFiltersAction
  ) => {
    ls.set<Filters>("filters", action.payload.updatedFilters);
    return Object.assign({}, state, {
      filters: action.payload.updatedFilters,
    });
  },
  [ActionType.ToToday]: (state: CalendarState, action: Action) => {
    const newDateBasis = calcDateBasis(new Date(), state.viewMode);
    const startDate = calcStartDate(newDateBasis, state.viewMode);
    const endDate = calcEndDate(newDateBasis, state.viewMode);

    return Object.assign({}, state, {
      dateBasis: newDateBasis,
      startDate: startDate,
      endDate: endDate,
      dates: getDates(startDate, endDate),
    });
  },
  [ActionType.ToNext]: (state: CalendarState, action: Action) => {
    const daysToAdd = state.viewMode === VIEW_MODES.ONE_DAY ? 1 : 7;
    const newDateBasis = dayjs(state.dateBasis).add(daysToAdd, "day").toDate();
    const startDate = calcStartDate(newDateBasis, state.viewMode);
    const endDate = calcEndDate(newDateBasis, state.viewMode);

    return Object.assign({}, state, {
      dateBasis: newDateBasis,
      startDate: startDate,
      endDate: endDate,
      dates: getDates(startDate, endDate),
    });
  },
  [ActionType.ToPrev]: (state: CalendarState, action: Action) => {
    const daysToSub = state.viewMode === VIEW_MODES.ONE_DAY ? 1 : 7;
    const newDateBasis = dayjs(state.dateBasis)
      .subtract(daysToSub, "day")
      .toDate();
    const startDate = calcStartDate(newDateBasis, state.viewMode);
    const endDate = calcEndDate(newDateBasis, state.viewMode);

    return Object.assign({}, state, {
      dateBasis: newDateBasis,
      startDate: startDate,
      endDate: endDate,
      dates: getDates(startDate, endDate),
    });
  },
  [ActionType.OpenJobDetail]: (
    state: CalendarState,
    action: OpenJobDetailAction
  ) => {
    return Object.assign({}, state, {
      selectedJobId: action.payload.job.id,
      selectedEventId: undefined,
    });
  },
  [ActionType.CloseJobDetail]: (
    state: CalendarState,
    action: CloseJobDetailAction
  ) => {
    return Object.assign({}, state, {
      selectedJobId: undefined,
      filters: action.payload.deleted
        ? {
            installTrades: [...state.filters.installTrades, -999],
            warrantyTrades: [...state.filters.warrantyTrades],
            warehouses: [...state.filters.warehouses],
            projectManagers: [...state.filters.projectManagers],
            jobStatuses: [...state.filters.jobStatuses],
          }
        : state.filters,
    });
  },
  [ActionType.OpenEventDetail]: (
    state: CalendarState,
    action: OpenEventDetailAction
  ) => {
    return Object.assign({}, state, {
      selectedEventId: action.payload.event.id,
      selectedJobId: undefined,
    });
  },
  [ActionType.CloseEventDetail]: (
    state: CalendarState,
    action: CloseEventDetailAction
  ) => {
    return Object.assign({}, state, {
      selectedEventId: undefined,
      filters: action.payload.deleted
        ? {
            installTrades: [...state.filters.installTrades, -999],
            warrantyTrades: [...state.filters.warrantyTrades],
            warehouses: [...state.filters.warehouses],
            projectManagers: [...state.filters.projectManagers],
            jobStatuses: [...state.filters.jobStatuses],
          }
        : state.filters,
    });
  },
  [ActionType.OpenTrades]: (state: CalendarState, action: OpenTradesAction) => {
    let tradesOpen: TradesOpen = {};
    action.payload.trades.forEach(v => {
      const key = `trade_open_${action.payload.type}__${v.id}`;
      const isOpen = { isOpen: true };

      ls.set<OpenStateStorage>(key, { isOpen: true });

      if (tradesOpen !== null) {
        tradesOpen[key] = isOpen
      }
    });

    return Object.assign({}, state, {
      tradesOpen: Object.assign({}, state.tradesOpen, {
        ...tradesOpen
      })
    });
  },
  [ActionType.SetInstallTrades]: (
    state: CalendarState,
    action: SetInstallTradesAction
  ) => {
    // Load localStorage values into the state.
    let tradesOpen: TradesOpen = {};
    action.payload.installTrades.forEach(v => {
      const key = `trade_open_Install__${v.id}`;
      if (tradesOpen !== null) {
        tradesOpen[key] = ls.get<OpenStateStorage>(key);
      }
    });

    return Object.assign({}, state, {
      installTrades: action.payload.installTrades,
      tradesOpen: Object.assign({}, state.tradesOpen, {
        ...tradesOpen
      })
    });
  },
  [ActionType.SetWarrantyTrades]: (
    state: CalendarState,
    action: SetWarrantyTradesAction
  ) => {
    // Load localStorage values into the state.
    let tradesOpen: TradesOpen = {};
    action.payload.warrantyTrades.forEach(v => {
      const key = `trade_open_Install__${v.id}`;
      if (tradesOpen !== null) {
        tradesOpen[key] = ls.get<OpenStateStorage>(key);
      }
    });

    return Object.assign({}, state, {
      warrantyTrades: action.payload.warrantyTrades,
      tradesOpen: Object.assign({}, state.tradesOpen, {
        ...tradesOpen
      })
    });
  },
  [ActionType.SetWarehouses]: (
    state: CalendarState,
    action: SetWarehousesAction
  ) => {
    return Object.assign({}, state, {
      warehouses: action.payload.warehouses,
    });
  },
  [ActionType.SetProjectManagers]: (
    state: CalendarState,
    action: SetProjectManagersAction
  ) => {
    return Object.assign({}, state, {
      projectManagers: action.payload.projectManagers,
    });
  },
  [ActionType.SetJobStatuses]: (
    state: CalendarState,
    action: SetJobStatusesAction
  ) => {
    return Object.assign({}, state, {
      jobStatuses: action.payload.jobStatuses,
    });
  },
  [ActionType.SetDates]: (state: CalendarState, action: SetDatesAction) => {
    return Object.assign({}, state, {
      dates: getDates(action.payload.startDate, action.payload.endDate),
    });
  },
  [ActionType.SetCacheUpdate]: (
    state: CalendarState,
    action: SetCacheUpdateAction
  ) => {
    return Object.assign({}, state, {
      cacheUpdate: action.payload.cacheUpdate,
    });
  },
  [ActionType.SetTradeOpen]: (state: CalendarState, action: SetTradeOpenAction) => {
    const key = `trade_open_${action.payload.type}__${action.payload.tradeId}`;
    const isOpen = { isOpen: action.payload.isOpen }

    ls.set<OpenStateStorage>(key, isOpen);

    return Object.assign({}, state, {
      tradesOpen: Object.assign({}, state.tradesOpen, {
        [key]: isOpen
      })
    })
  },
  [ActionType.SetResourcesSearch]: (state: CalendarState, action: SetResourcesSearchAction): CalendarState => {
    return Object.assign({}, state, {
      resourcesSearch: action.payload.search,
    });
  }
};

export default function reducer(state: CalendarState, action: Action) {
  const handler: Function | undefined = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
