import { gql } from '@apollo/client';

export const GET_PROPERTY_ADDRESS = gql`
  query getPropertyAddress($id: ID!) {
    getPropertyAddress(id: $id) {
      id
      address
      jobNumber
      builder {
        id
        name
      }
      subdivision {
        id
        name
      }
      projectManagers {
        id
        firstName
        lastName
        fullName
        phoneNumber
        email
      }
      jobs {
        id
        name
        startDate
        endDate
        notes
        jobNumber
        recurring
        jobStatuses {
          id
          name
          iconCode
        }
        jobType {
          id
          name
        }
        trade {
          id
          name
        }
        calendarResource {
          id
          name
          isActive
        }
        warehouse {
          id
          name
        }
        jobAttributeValues {
          id
          value
          jobAttribute {
            id
          }
        }
      }
    }
  }
`;
