import React from 'react';
import styled from 'styled-components/macro';
import { theme } from 'styled-tools';
import { gql, useQuery } from "@apollo/client";
import { DropDown, Option, useFocusEventHandler, useSearchState } from './index';

interface AutocompleteFieldProps {
  label?: string,
  setAddress?: Function
}

const InputWrapper = styled.div`
  width: 100%;

  label {
    display: block;
  }

  input {
    width: 100%;
    padding: 0.5rem;
    border-radius: 4px;
    border: solid 1px ${theme('colors.inputGray')};

    &:focus-visible {
      border-color: ${theme('colors.lightBlue')};
      outline: none;
    }
  }
`;

const AddressFieldWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const GET_ADDRESSES = gql`
  query searchPropertyAddresses($search: String) {
    searchPropertyAddresses(search: $search) {
      id
      address
    }
  }
`;

function AddressField({
  label,
  setAddress,
}: AutocompleteFieldProps) {
  const { searchFilter, searchValue, handleOptionSelected, onSearchChange } = useSearchState('');
  const { isFocused, setIsFocused, handleFocusEvent } = useFocusEventHandler();

  const { loading, data } = useQuery(GET_ADDRESSES, {
    variables: { search: searchFilter },
  });

  const handleClick = (option: any) => {
    handleOptionSelected(option.label);
    setIsFocused(false);
    setAddress && setAddress(option.value);
  }

  const parseResponse = (data: any): Option[] => {
    return data ? data.searchPropertyAddresses.map((option: any) => {
      return { value: option.id, label: option.address }
    }) : [];
  }

  return (
    <AddressFieldWrapper>
      <InputWrapper>
        {label && <label >{label}</label>}
        <input
          id='address-field'
          value={searchValue}
          onChange={onSearchChange}
          onFocus={handleFocusEvent}
          autoComplete='off'
        />
      </InputWrapper>
      {(!loading && isFocused) &&
        <DropDown>
          {parseResponse(data).map((option: any) => (
            <li onClick={() => handleClick(option)} key={option.value} tabIndex={0}>{option.label}</li>
          ))}
        </DropDown>
      }
    </AddressFieldWrapper>
  );
}

export default AddressField;
