import Icon from 'components/Icon';
import React, {useCallback, useContext} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { gql, useQuery } from '@apollo/client';
import { Input as BaseInput, InputAdornment, useMediaQuery, useTheme } from '@material-ui/core';
import { openJobDetail } from 'contexts/CalendarContext/reducer';
import { useFocusEventHandler, useSearchState } from './index';
import { theme } from 'styled-tools';
import { useHistory, useLocation } from 'react-router-dom';
import {Job} from 'types';
import { CalendarDispatchContext } from 'contexts/CalendarContext';
import {CalendarJobFields} from 'graphql/fragments/CalendarJobFields';
import { formatGMT } from 'utils/dates';

interface SearchInputProps {
  placeholder?: string;
}

const Input = styled(BaseInput)`
    padding: 0 1.5rem;
    color: ${theme('colors.inputGray')};
    align-items: left;

    ${props => props.theme.breakpoints.up('md')} {
      border-radius: ${theme('borderRadius')};
      box-shadow: ${theme('boxShadow.light')};
    }

    & .MuiInputAdornment-positionEnd {
      margin-left: 2rem;

      ${props => props.theme.breakpoints.up('md')} {
        margin-left: 1rem;
      }
    }
`;

export const JobDropDown = styled.ul`
  position: absolute;
  margin: 0;
  padding: 0;
  z-index: 1;
  overflow: auto;
  border: 1px solid ${theme('colors.lightGrey')};
  border-radius: 4px;
  background-color: ${theme('colors.white')};

  & li[data-focus="true"] {
    background-color: ${theme('colors.lightGrey')};
    cursor: pointer;
  }

  & li {
    cursor: pointer;
    padding: 0.5rem;

    &:active,
    &:hover {
      background-color: #2977f5;
      color: ${theme('colors.lightGrey')};
    }

    &:not(:last-child) {
      border-bottom: 1px solid ${theme('colors.lightGrey')};
    }
  }
`;

const SearchResultAddress = styled.span`
  display: block;
`;

const SEARCH_JOB_BY_ADDRESSES = gql`
  query searchJobsByAddress($search: String) {
    searchJobsByAddress(search: $search) {
      ...CalendarJobFields
    }
  }
  ${CalendarJobFields}
`;

function SearchInput({ placeholder = 'What are you looking for?' }: SearchInputProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const dispatch = useContext(CalendarDispatchContext);
  const { searchFilter, searchValue, setSearchValue, handleOptionSelected, onSearchChange } = useSearchState('');
  const { isFocused, setIsFocused, handleFocusEvent } = useFocusEventHandler();
  const { loading, data } = useQuery(SEARCH_JOB_BY_ADDRESSES, {
    variables: { search: searchFilter },
  });
  const location = useLocation();
  const history = useHistory();

  const handleJobClick = useCallback((job: Job) => {
    handleOptionSelected(job.propertyAddress && job.propertyAddress.address);
    setIsFocused(false);
    dispatch(openJobDetail(job));
    setSearchValue('');

    if (location.pathname !== '/') {
      history.push('/');
    }
  }, [dispatch, handleOptionSelected, history, location.pathname, setIsFocused, setSearchValue]);

  const parseResponse = (data: any): Job[] => {
    return data ? data.searchJobsByAddress.map((option: any) => {
      return option;
    }) : [];
  }

  const searchResults = parseResponse(data);

  return (
    <div>
      <Input
        id='search-field'
        type='text'
        placeholder={!isMobile ? placeholder : ''}
        endAdornment={
          <InputAdornment position='end'>
            <Icon icon='search' size={0.75} />
          </InputAdornment>
        }
        disableUnderline={true}
        value={searchValue}
        onChange={onSearchChange}
        onFocus={handleFocusEvent}
        onBlur={handleFocusEvent}
        autoComplete='off'
      />
      {(!loading && isFocused) && searchResults.length > 0 &&
        <JobDropDown>
          {searchResults.map((option: any) => (
            <li onFocus={handleFocusEvent} onClick={() => handleJobClick(option)} key={option.id} tabIndex={0}>
              <SearchResultAddress>
                {option.propertyAddress.address}
                {option.propertyAddress.subdivision && <span>, {option.propertyAddress.subdivision.name}</span>}
              </SearchResultAddress>
              <span>{option?.jobType?.name}&nbsp;{option?.trade?.name}</span>
              <span> - {option?.calendarResource?.name || 'UNASSIGNED'}</span>
              <span> - {formatGMT(new Date(option.startDate), 'YYYY-MM-DD')}</span>
            </li>
          ))}
        </JobDropDown>
      }
    </div>
  );
}

SearchInput.propTypes = {
  placeholder: PropTypes.string,
}

export default SearchInput;
