import styled from 'styled-components/macro';
import { theme } from 'styled-tools';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';

const headerHeight = '8rem';

export const LoginContainer = styled(Container)`
  max-width: 25rem;

  .MuiAlert-root {
    margin-bottom: 1rem;
  }
`;

export const LoginWrapper = styled.div`
  background-color: ${theme('colors.lightGrey')};
  min-height: 100%;
  display: flex;
  flex-direction: column;
`;

export const LoginHeader = styled.header`
  background-color: ${theme('colors.white')};
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${headerHeight};
  flex: 0 0 auto;
`;

export const LoginBody = styled.main`
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LoginPaper = styled(Paper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: none;
`;

export const FormHeader = styled.div`
  height: 5rem;
  width: 100%;
  background-color: ${theme('colors.yellow')};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;

  img {
    max-height: 100%;
    max-width: 100%;
  }
`;

export const FormBody = styled(Box)`
  text-align: center;
  margin: 2rem;
`;

export const SignInSeparator = styled.p`
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-weight: bold;
`;
