import React, { lazy, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import ForgotPassword from './routes/ForgotPassword';
import PrivateRoute from './components/PrivateRoute';

const ReportsPage = lazy(() => import('./routes/Reports'));
const CalendarPage = lazy(() => import('./routes/Calendar'));
const LoginPage = lazy(() => import('./routes/Login'));

function Routes() {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Switch>
        <Route path='/login' component={LoginPage} exact />
        <Route path='/forgot-password/:resetToken?' component={ForgotPassword} exact />

        <PrivateRoute path='/reports'>
          <ReportsPage />
        </PrivateRoute>

        <PrivateRoute path='/' exact>
          <CalendarPage />
        </PrivateRoute>

        <Redirect to='/404' />
      </Switch>
    </Suspense>
  );
}

export default Routes;
