import { Event, Job, JobType } from 'types';
import dayjs from 'dayjs';
import { rrulestr } from 'rrule';
import { dateGMT } from 'utils/dates';
import { Filters } from 'contexts/CalendarContext';

export function groupJobsByDate(dates: Date[], jobs: Job[]|undefined): Record<string, Job[]> {
  const gmtDates = dates.map(x => dateGMT(x, true));
  const firstDate = dayjs.min(gmtDates).startOf('day');
  const lastDate = dayjs.max(gmtDates).endOf('day');

  const initialValue = gmtDates.reduce((grouped, date) => {
    grouped[date.format('YYYY-MM-DD')] = [];

    return grouped;
  }, {} as Record<string, Job[]>);

  if (!jobs) {
    return initialValue;
  }

  return jobs.reduce((groupedByDate, job) => {
    const days = firstDate && lastDate
      ? getAssignedResourceDates(job, { startDate: firstDate.toDate(), endDate: lastDate.toDate() })
      : [];

    days.forEach(day => {
      const formattedDate = dateGMT(day).format('YYYY-MM-DD');
      if (groupedByDate.hasOwnProperty(formattedDate)) {
        groupedByDate[formattedDate].push(job);
      }
    });

    return groupedByDate;
  }, initialValue);
}

export function groupEventsByDate(dates: Date[], events: Event[]|undefined): Record<string, Event[]> {
  const gmtDates = dates.map(x => dateGMT(x));
  const firstDate = dayjs.min(gmtDates);
  const lastDate = dayjs.max(gmtDates);

  const initialValue = gmtDates.reduce((grouped, date) => {
    grouped[date.format('YYYY-MM-DD')] = [];

    return grouped;
  }, {} as Record<string, Event[]>);

  if (!events) {
    return initialValue;
  }

  return events.reduce((groupedByDate, event) => {
    const days = firstDate && lastDate
      ? getAssignedResourceDates(event, { startDate: firstDate.toDate(), endDate: lastDate.toDate() })
      : [];

    days.forEach(day => {
      const formattedDate = dateGMT(day).format('YYYY-MM-DD');
      if (groupedByDate.hasOwnProperty(formattedDate)) {
        groupedByDate[formattedDate].push(event);
      }
    });

    return groupedByDate;
  }, initialValue);
}

export function getAssignedResourceDates(occurrence: Job|Event, betweenDates?: { startDate: Date, endDate: Date }): Date[] {
  if (occurrence.recurring) {
    const rule = rrulestr(occurrence.recurring);

    if (betweenDates) {
      return rule.between(betweenDates.startDate, betweenDates.endDate, true);
    }

    return rule.all();
  }

  const occurrenceDays = [];

  const startDate = dateGMT(occurrence.startDate);
  if (!betweenDates || startDate.isBetween(betweenDates.startDate, betweenDates.endDate, null, '[]')) {
    occurrenceDays.push(startDate.toDate());
  }

  if (occurrence.endDate) {
    let dateCursor = startDate.add(1, 'day');
    const endDate = dateGMT(occurrence.endDate);

    while (endDate.isSameOrAfter(dateCursor)) {
      if (!betweenDates || dateCursor.isBetween(betweenDates.startDate, betweenDates.endDate, null, '[]')) {
        occurrenceDays.push(dateCursor.toDate());
      }

      dateCursor = dateCursor.add(1, 'day');
    }
  }

  return occurrenceDays;
}

export function deserializeAttributeValue(value?: string): any {
  if (typeof value === 'undefined') {
    return undefined;
  }

  try {
    return JSON.parse(value);
  } catch (e) {
    return value.toString();
  }
}

export function serializeAttributeValue(value: any): string {
  return JSON.stringify(value);
}

export function jobMatchesFilters(
  job: Job,
  startDate: Date | null,
  endDate: Date | null,
  filters: Filters,
  installJobType: JobType | undefined,
  warrantyJobType: JobType | undefined
) {
  const installTradeIds = filters.installTrades || [];
  const warrantyTradeIds = filters.warrantyTrades || [];
  const jobStatusIds = filters.jobStatuses || [];
  const projectManagerIds = filters.projectManagers || [];
  const warehouseIds = filters.warehouses || [];
  const installFilter = installTradeIds.length > 0;
  const warrantyFilter = warrantyTradeIds.length > 0;
  const noProjectManagerSet = projectManagerIds.some(id => id === -1);
  const noWarehouseSet = warehouseIds.some(id => id === -1);

  let jobTypeAndTradeMatch: boolean | undefined = false;
  if (installFilter && warrantyFilter) {
    jobTypeAndTradeMatch = (job.jobType?.id === installJobType?.id && (job.trade && installTradeIds.includes(job.trade.id)))
      || (job.jobType?.id === warrantyJobType?.id && (job.trade && warrantyTradeIds.includes(job.trade.id)));
  } else if (installFilter) {
    jobTypeAndTradeMatch = job.jobType?.id === installJobType?.id && (job.trade && installTradeIds.includes(job.trade.id));
  } else if (warrantyFilter) {
    jobTypeAndTradeMatch = job.jobType?.id === warrantyJobType?.id && (job.trade && warrantyTradeIds.includes(job.trade.id));
  } else {
    jobTypeAndTradeMatch = true;
  }

  // Default to true so if there are no jobStatusIds to check, this passes
  let jobStatusMatch: boolean | undefined = true;
  if (jobStatusIds.length > 0) {
    jobStatusMatch = job.jobStatuses && job.jobStatuses.map(status => status.id).some(jobStatusId => jobStatusIds.includes(jobStatusId));
  }

  const jobHasProjectManagers = job.propertyAddress && job.propertyAddress.projectManagers && job.propertyAddress.projectManagers.length > 0;

  // Default to true so if there are no projectManagerIds to check, this passes
  let projectManagerMatch: boolean | undefined = true;
  if ((projectManagerIds.length > 0 || noProjectManagerSet) ) {
    if (projectManagerIds.length > 1 && noProjectManagerSet) {
      projectManagerMatch = !jobHasProjectManagers || job?.propertyAddress?.projectManagers
        ?.map(projectManager => projectManager.id)
        .some(projectManagerId => projectManagerIds.includes(projectManagerId));
    } else if (noProjectManagerSet) {
      projectManagerMatch = !jobHasProjectManagers;
    } else {
      projectManagerMatch = job?.propertyAddress?.projectManagers
        ?.map(projectManager => projectManager.id)
        .some(projectManagerId => projectManagerIds.includes(projectManagerId));
    }
  }

  let warehouseMatch: boolean | undefined = true;
  if (warehouseIds.length > 1 && noWarehouseSet) {
    warehouseMatch = !job.warehouse || warehouseIds.includes(job?.warehouse.id);
  } else if (noWarehouseSet) {
    warehouseMatch = !job.warehouse;
  } else if (warehouseIds.length > 0) {
    warehouseMatch = job.warehouse && warehouseIds.includes(job.warehouse.id);
  }

  const jobStartDate = job.startDate ? new Date(job.startDate) : null;
  const jobEndDate = job.endDate ? new Date(job.endDate) : null;
  const datesMatch = jobStartDate && startDate && jobEndDate && endDate
    ? (jobStartDate >= startDate && jobStartDate <= endDate) ||
      (jobEndDate >= startDate && jobEndDate <= endDate)
    : false;

  return jobTypeAndTradeMatch && jobStatusMatch && projectManagerMatch && warehouseMatch && datesMatch;
}

export function eventMatchesFilters(
  event: Event,
  startDate: Date | null,
  endDate: Date | null,
  filters: Filters,
  installJobType: JobType | undefined,
  warrantyJobType: JobType | undefined
) {
  const installTradeIds = filters.installTrades || [];
  const warrantyTradeIds = filters.warrantyTrades || [];
  const installFilter = installTradeIds.length > 0;
  const warrantyFilter = warrantyTradeIds.length > 0;

  let calendarResourceTypeAndTradeMatch: boolean | undefined = false;
  if (installFilter && warrantyFilter) {
    calendarResourceTypeAndTradeMatch = (event.calendarResource.jobType?.id === installJobType?.id && (event.calendarResource.trade && installTradeIds.includes(event.calendarResource.trade.id)))
      || (event.calendarResource.jobType?.id === warrantyJobType?.id && (event.calendarResource.trade && warrantyTradeIds.includes(event.calendarResource.trade.id)));
  } else if (installFilter) {
    calendarResourceTypeAndTradeMatch = event.calendarResource.jobType?.id === installJobType?.id && (event.calendarResource.trade && installTradeIds.includes(event.calendarResource.trade.id));
  } else if (warrantyFilter) {
    calendarResourceTypeAndTradeMatch = event.calendarResource.jobType?.id === warrantyJobType?.id && (event.calendarResource.trade && warrantyTradeIds.includes(event.calendarResource.trade.id));
  } else {
    calendarResourceTypeAndTradeMatch = true;
  }

  const eventStartDate = event.startDate ? new Date(event.startDate) : null;
  const eventEndDate = event.endDate ? new Date(event.endDate) : null;
  const datesMatch = eventStartDate && startDate && eventEndDate && endDate
    ? (eventStartDate >= startDate && eventStartDate <= endDate) ||
    (eventEndDate >= startDate && eventEndDate <= endDate)
    : false;

  return calendarResourceTypeAndTradeMatch && datesMatch;
}

export function areFiltersEmpty(filters: Filters): boolean {
  return (
    filters.installTrades.length === 0 &&
    filters.warrantyTrades.length === 0 &&
    filters.warehouses.length === 0 &&
    filters.projectManagers.length === 0 &&
    filters.jobStatuses.length === 0
  );
}
