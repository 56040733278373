import React from 'react';
import styled from 'styled-components/macro';
import { theme } from 'styled-tools';
import PhoneNumber from 'components/PhoneNumber';

interface AddressInfoProps {
  address: any,
}

interface ProjectManager {
  id: number,
  firstName?: string,
  lastName?: string,
  phoneNumber?: string,
  email?: string,
}

const BuilderContainer = styled.div`
  display: flex;
  flex-basis: 45%;
  flex-direction: column;
  margin-left: 0.75rem;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
`;

const InfoLabel = styled.span`
  font-weight: bold;
`;

const InfoDisplay = styled.span`
  padding: 0.5rem;
  color: ${theme('colors.darkBlue')};
  font-weight: bold;
  border: solid 1px ${theme('colors.lightGrey')};
  border-radius: 4px;
  margin-bottom: 0.5rem;
`;

function AddressInfoSection({ address }: AddressInfoProps) {
  return (
    <BuilderContainer>
      <InfoWrapper>
        <InfoLabel>Builder</InfoLabel>
        <InfoDisplay>{address?.builder?.name ?? '-'}</InfoDisplay>
      </InfoWrapper>

      <InfoWrapper>
        <InfoLabel>Builder Subdivision</InfoLabel>
        <InfoDisplay>{address?.subdivision?.name ?? '-'}</InfoDisplay>
      </InfoWrapper>

      <InfoWrapper>
        <InfoLabel>Project Manager</InfoLabel>
        {address?.projectManagers?.length > 0 ? (
          address.projectManagers.map((pm: ProjectManager) => (
            <InfoDisplay key={pm.id}>
              {pm.firstName} {pm.lastName}
              {pm.phoneNumber && <><br /><small><PhoneNumber value={pm.phoneNumber} /></small></>}
            </InfoDisplay>
          ))
        ) : (
          <InfoDisplay>-</InfoDisplay>
        )}
      </InfoWrapper>
      {address?.jobNumber && (
        <InfoWrapper>
          <InfoLabel>Job Number:</InfoLabel>
          <InfoDisplay>{address?.jobNumber}</InfoDisplay>
        </InfoWrapper>
      )}
    </BuilderContainer>
  );
}

export default AddressInfoSection;
