import styled, { css } from 'styled-components/macro';
import { ifProp, theme } from 'styled-tools';

interface InputWrapperProps {
  flexBasis?: string;
  isCheckbox?: boolean;
  inline?: boolean;
  flexEnd?: boolean;
  isFilter?: boolean;
}

export const InputWrapper = styled.div<InputWrapperProps>`
  && {
    flex-basis: ${props => (props.flexBasis ? props.flexBasis : '')};

    ${props => (!props.isFilter ? (css`
      margin-bottom: 1rem;
    `) : '')}

    ${ifProp('flexEnd', css`
      display: flex;
      align-items: flex-end;
    `)}
  }

  label {
    font-weight: ${props => (props.isFilter ? 'normal' : 'bold')};
    display: block;

    ${ifProp('isCheckbox', css`
      display: inline-block;
    `)}

    ${ifProp('inline', css`
      display: flex;
      align-items: center;
    `)}
  }

  input {
    padding: 0.5rem;
    color: ${theme('colors.darkBlue')};
    font-weight: bold;
    border: solid 1px ${theme('colors.lightGrey')};
    border-radius: 4px;
    width: 100%;

    ${props => (props.isCheckbox || props.inline ? (css`
      width: unset;
    `) : '')}

    ${props => (props.isCheckbox && !props.isFilter ? (css`
      margin-left: 1rem;
    `) : '')}
  }
`;
