import { gql } from '@apollo/client';

export const CalendarJobFields = gql`
  fragment CalendarJobFields on Job {
    id
    name
    startDate
    endDate
    recurring
    notes
    jobNumber
    createdAt
    updatedAt
    jobType {
      id
      name
    }
    trade {
      id
      name
    }
    calendarResource {
      id
      name
    }
    warehouse {
      id
      name
    }
    jobStatuses {
      id
      name
      iconCode
      iconExtraClasses
    }
    jobAttributeValues {
      id
      value
      jobAttribute {
        id
        label
        displayOnCard
        specialBehavior
        specialBehaviorOptions
      }
    }
    propertyAddress {
      id
      address
      builder {
        id
        name
      }
      subdivision {
        id
        name
      }
    }
  }
`;
