import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { ThemeProvider, StylesProvider, createGenerateClassName } from '@material-ui/core/styles';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { HelmetProvider } from 'react-helmet-async';
import Routes from './routes';
import theme from './theme';
import Layout from './components/Layout';
import { useAppApolloClient } from './config/apolloClient';
import { useAuthData, useAuthToken } from "./config/auth";
import { useHistory } from 'react-router-dom';
import { CalendarProvider } from "contexts/CalendarContext";
import ErrorBoundary from 'components/ErrorBoundary';

const generateClassName = createGenerateClassName();

function App() {
  const apolloClient = useAppApolloClient();
  const [authToken,,removeAuthToken] = useAuthToken();
  const authData = useAuthData();
  const history = useHistory();

  useEffect(() => {
    if (authToken && authData?.exp) {
      const newLogoutInterval = setInterval(() => {
        const now = new Date().getTime() / 1000;
        if (authData?.exp && authData.exp < now) {
          removeAuthToken();
          history.push('/login');
        }
      }, 60 * 1000);

      return () => {
        clearInterval(newLogoutInterval);
      };
    }
  }, [removeAuthToken, authToken, authData, history]);

  return (
    <BrowserRouter>
      <HelmetProvider>
        <ApolloProvider client={apolloClient}>
          <StylesProvider generateClassName={generateClassName} injectFirst>
            <ThemeProvider theme={theme}>
              <StyledThemeProvider theme={theme}>
                <CalendarProvider>
                  <Layout>
                    <ErrorBoundary>
                      <Routes />
                    </ErrorBoundary>
                  </Layout>
                </CalendarProvider>
              </StyledThemeProvider>
            </ThemeProvider>
          </StylesProvider>
        </ApolloProvider>
      </HelmetProvider>
    </BrowserRouter>
  );
}

export default App;
