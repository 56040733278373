import { useQuery, gql } from "@apollo/client";

const GET_TRADES = gql`
  query getTrades {
    listTrades {
      id
      name
      jobTypes {
        id
        name
      }
      jobAttributes {
        id
        label
        required
        fieldType
        fieldOptions
        specialBehavior
        specialBehaviorOptions
        jobTypes {
          id
          name
        }
      }
    }
  }
`;

function useGetTrades(options?: any) {
  return useQuery(GET_TRADES, options);
}

export default useGetTrades;
