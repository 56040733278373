import React, { createContext, useContext, useReducer } from 'react';
import dayjs from "dayjs";
import { gql, useQuery } from "@apollo/client";
import { JobType, Trade } from 'types/index';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';
import { isMobile } from 'react-device-detect';
import CalendarReducer, {
  calcDateBasis,
  calcStartDate,
  calcEndDate,
  setInstallTrades,
  setWarrantyTrades,
  setWarehouses,
  setProjectManagers,
  setJobStatuses,
  CalendarState,
  setDates
} from './reducer';

export const VIEW_MODES = {
  SEVEN_DAY: 'seven_day',
  TEN_DAY: 'ten_day',
  ONE_DAY: 'one_day',
}

export interface Filters {
  installTrades: number[],
  warrantyTrades: number[],
  projectManagers: number[],
  warehouses: number[],
  jobStatuses: number[],
}

interface CalendarProviderProps {
  children: React.ReactNode,
}

interface QueryParams {
  date?: string,
  viewMode?: string,
}

const GET_FILTERS_DATA = gql`
  query getFiltersData {
    listJobTypes {
      id
      name
    }
    listTrades {
      id
      name
      sortOrder
      jobTypes {
        id
        name
      }
    }
    listWarehouses {
      id
      name
    }
    listProjectManagers {
      id
      fullName
    }
    listJobStatuses {
      id
      name
      description
      statusCode
      disableManualSelection
    }
  }
`;

const contextInitialState: CalendarState = {
  viewMode: '',
  dateBasis: null,
  startDate: null,
  endDate: null,
  dates: [],
  installTrades: [],
  warrantyTrades: [],
  warehouses: [],
  projectManagers: [],
  jobStatuses: [],
  filters: {
    installTrades: [], warrantyTrades: [], warehouses: [], projectManagers: [], jobStatuses: []
  },
  selectedJobId: undefined,
  selectedEventId: undefined,
  cacheUpdate: false,
  tradesOpen: null,
  resourcesSearch: '',
};

export const CalendarContext = createContext<CalendarState>(contextInitialState);
export const CalendarDispatchContext = createContext<React.Dispatch<any>>(() => null);

export function CalendarProvider({ children }: CalendarProviderProps) {
  const location = useLocation();
  const { date: dateParam, viewMode: viewModeParam }: QueryParams = qs.parse(location.search);

  const initialViewMode = !isMobile ? viewModeParam ?? VIEW_MODES.SEVEN_DAY : VIEW_MODES.ONE_DAY
  const initialDateBasis = calcDateBasis(dayjs(dateParam).toDate(), initialViewMode);
  const startDate = calcStartDate(initialDateBasis, initialViewMode);
  const endDate = calcEndDate(initialDateBasis, initialViewMode);
  const initialState: CalendarState = {
    viewMode: initialViewMode,
    dateBasis: initialDateBasis,
    startDate: startDate,
    endDate: endDate,
    dates: [],
    installTrades: [],
    warrantyTrades: [],
    warehouses: [],
    projectManagers: [],
    jobStatuses: [],
    filters: {
      installTrades: [], warrantyTrades: [], warehouses: [], projectManagers: [], jobStatuses: []
    },
    selectedJobId: undefined,
    selectedEventId: undefined,
    cacheUpdate: false,
    tradesOpen: null,
    resourcesSearch: '',
  };

  const [state, dispatch] = useReducer(CalendarReducer, initialState);

  useQuery(GET_FILTERS_DATA, {
    onCompleted: (data) => {
      const jobTypes: JobType[] = data.listJobTypes;
      const trades: Trade[] = data.listTrades;

      const installId = jobTypes.filter(jobType => {
        return jobType.name === 'Install';
      })[0].id;
      const warrantyId = jobTypes.filter(jobType => {
        return jobType.name === 'Warranty';
      })[0].id;

      const installTrades = trades.filter(trade => {
        return trade.jobTypes && trade.jobTypes.some(jobType => jobType.id === installId);
      });
      const warrantyTrades = trades.filter(trade => {
        return trade.jobTypes && trade.jobTypes.some(jobType => jobType.id === warrantyId);
      });

      dispatch(setInstallTrades(installTrades));
      dispatch(setWarrantyTrades(warrantyTrades));
      dispatch(setWarehouses([...data.listWarehouses, {id: -1, name: 'No Warehouse Set'}]));
      dispatch(setProjectManagers([...data.listProjectManagers, {id: -1, fullName: 'No Project Manager Set'}]));
      dispatch(setJobStatuses(data.listJobStatuses));
      dispatch(setDates(startDate, endDate));
    }
  });

  return (
    <CalendarContext.Provider value={state}>
      <CalendarDispatchContext.Provider value={dispatch}>
        {children}
      </CalendarDispatchContext.Provider>
    </CalendarContext.Provider>
  );
}

export const CalendarConsumer = CalendarContext.Consumer;
export const CalendarDispatchConsumer = CalendarDispatchContext.Consumer;

// Use this component to destructure the state and pass along only those parts of state
// that the children actually need. This avoids unnecessary re-renders when portions of
// state that they aren't using are changed via context.
export const CalendarContextConsumer = ({ children }: { children: Function }) => {
  const state = useContext(CalendarContext);
  const dispatch = useContext(CalendarDispatchContext);

  return (
    <>{children(state, dispatch)}</>
  );
}

export const useCalendarContext = () => useContext(CalendarContext);
